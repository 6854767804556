import { Filter, createFilter, FilterType, Filters } from "@retail/gql-utils";
import { addDays } from "date-fns";
import { DebtCollectionFilterVM } from "../DebtCollectionFilter";
import { formatISODateTime } from "./useOrderDebts/utils";

export const addOneDayAndFormatISO = (date: Date | undefined) =>
  date && formatISODateTime(addDays(date, 1));

const createIsNotNullFilter = (field: string) => ({
  op: FilterType.IS_NOT_NULL,
  field,
});

export const buildDebtCollectionFilter = (
  filter: DebtCollectionFilterVM
): Filter => {
  const filters: Array<Filter> = [];
  // @ts-ignore
  filters.push(Filters.isNull("paidOffOn"));
  if (filter.country?.length) {
    filters.push(createFilter("orderCountry", FilterType.IN, filter.country));
  }
  if (filter.debtType) {
    filters.push(Filters.equal("type", filter.debtType));
  }
  if (typeof filter.eligibleForCollection === "boolean") {
    filters.push(
      // @ts-ignore
      filter.eligibleForCollection
        ? createIsNotNullFilter("eligibleOn")
        : Filters.isNull("eligibleOn")
    );
  }
  if (filter.orderNumber) {
    filters.push(Filters.equal("orderNumber", filter.orderNumber));
  }
  if (filter.paymentFlow) {
    filters.push(Filters.equal("orderPaymentFlow", filter.paymentFlow));
  }
  if (filter.paymentType) {
    filters.push(Filters.equal("orderFinancingFlow", filter.paymentType));
  }
  if (filter.eligibleOnDateRange?.[0]) {
    filters.push(
      Filters.greaterThan(
        "eligibleOn",
        formatISODateTime(filter.eligibleOnDateRange?.[0])
      )
    );
  }
  if (filter.eligibleOnDateRange?.[1]) {
    filters.push(
      Filters.lessOrEqual(
        "eligibleOn",
        addOneDayAndFormatISO(filter.eligibleOnDateRange?.[1])
      )
    );
  }
  if (filter.deliveryDateRange?.[0]) {
    filters.push(
      Filters.greaterThan(
        "orderVehicleDeliveredOn",
        formatISODateTime(filter.deliveryDateRange?.[0])
      )
    );
  }
  if (filter.deliveryDateRange?.[1]) {
    filters.push(
      Filters.lessOrEqual(
        "orderVehicleDeliveredOn",
        addOneDayAndFormatISO(filter.deliveryDateRange?.[1])
      )
    );
  }
  if (filter.orderCreationDateRange?.[0]) {
    filters.push(
      Filters.greaterThan(
        "orderCreatedOn",
        formatISODateTime(filter.orderCreationDateRange?.[0])
      )
    );
  }
  if (filter.orderCreationDateRange?.[1]) {
    filters.push(
      Filters.lessThan(
        "orderCreatedOn",
        addOneDayAndFormatISO(filter.orderCreationDateRange?.[1])
      )
    );
  }
  if (filter.debtCreationDateRange?.[0]) {
    filters.push(
      Filters.greaterThan(
        "createdOn",
        formatISODateTime(filter.debtCreationDateRange?.[0])
      )
    );
  }
  if (filter.debtCreationDateRange?.[1]) {
    filters.push(
      Filters.lessThan(
        "createdOn",
        addOneDayAndFormatISO(filter.debtCreationDateRange?.[1])
      )
    );
  }
  return filters.length > 1
    ? (Filters.and(...filters) as Filter)
    : filters[0] ?? null;
};
