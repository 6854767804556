import { PathRouteProps } from "react-router-dom";
import { Permission } from "./acl";
import { routePathByPage } from "./routePathByPage";
import { DebtCollectionOverview } from "./pages/DebtCollectionOverview";

const routes: Array<PathRouteProps & { permissions: Permission[] }> = [
  {
    path: routePathByPage.overview,
    element: <DebtCollectionOverview />,
    permissions: ["searchOrderDebts"],
  },
];

export default routes;
