export enum DebtType {
  TradeIn = "TRADE_IN",
  DoubleRefund = "DOUBLE_REFUND",
  Chargeback = "CHARGEBACK",
  CustomerOwesMoney = "CUSTOMER_OWES_MONEY",
  Financing = "FINANCING",
  TradeInPurchasePending = "TRADE_IN_PURCHASE_PENDING",
}

export const labelByDebtType: Record<DebtType, string> = {
  [DebtType.TradeIn]: "Trade-in price change",
  [DebtType.DoubleRefund]: "Double refund",
  [DebtType.Chargeback]: "Chargeback",
  [DebtType.CustomerOwesMoney]: "Customer owes money",
  [DebtType.Financing]: "Financing",
  [DebtType.TradeInPurchasePending]: "Trade-in purchase pending",
};

export enum OrderPaymentFlow {
  PayOnDelivery = "PAY_ON_DELIVERY",
  PayLater = "PAY_LATER",
}

export const labelByOrderPaymentFlow: Record<OrderPaymentFlow, string> = {
  [OrderPaymentFlow.PayOnDelivery]: "Pay on delivery",
  [OrderPaymentFlow.PayLater]: "Pay later",
};

export const DATE_FORMAT = "dd/MM/yyyy";
