import { SortOrder } from "antd/lib/table/interface";
import { useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export type TableState<T> = {
  pageSize: number;
  page: number;
  sortOrder?: SortOrder;
  sortField?: keyof T;
};

export const defaultPagination = {
  page: 1,
  pageSize: 50,
};

export const useTableState = <T>() => {
  const [search, setSearch] = useSearchParams();

  const tableState: TableState<T> = useMemo(
    () => ({
      page: +(search.get("page") ?? defaultPagination.page),
      pageSize: +(search.get("pageSize") ?? defaultPagination.pageSize),
      sortOrder: (search.get("sortOrder") as SortOrder) ?? undefined,
      sortField: (search.get("sortField") as keyof T) ?? undefined,
    }),
    [search]
  );

  const updateTableState = useCallback(
    (tableState: TableState<T>) => {
      search.set("page", tableState.page.toString());
      search.set("pageSize", tableState.pageSize.toString());
      if (tableState.sortOrder && tableState.sortField) {
        search.set("sortOrder", tableState.sortOrder);
        search.set("sortField", tableState.sortField as string);
      } else {
        search.delete("sortOrder");
        search.delete("sortField");
      }
      setSearch(search);
    },
    [search, setSearch]
  );

  return [tableState, updateTableState] as const;
};
