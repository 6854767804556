import { useQuery } from "react-query";
import { getApiClient } from "@/apiClient";
import { SearchOrderDebtsQuery } from "@/generated/graphql";
import { useDebtCollectionFilter } from "./../../DebtCollectionFilter";
import { useTableState } from "../useTableState";
import { adaptToSearchOrderDebtsVariables } from "../adaptToSearchOrderDebtsVariables";

export type OrderDebt = NonNullable<
  NonNullable<
    NonNullable<SearchOrderDebtsQuery["searchOrderDebts"]>["entities"]
  >[number]
>;

export const useOrderDebts = () => {
  const [filter] = useDebtCollectionFilter();
  const [tableState] = useTableState();

  return useQuery(
    ["orderDebts", tableState, filter],
    () =>
      getApiClient().SearchOrderDebts(
        adaptToSearchOrderDebtsVariables(filter, tableState)
      ),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: ({ searchOrderDebts }) => ({
        // TODO: wait for backend integration
        total: searchOrderDebts?.totalEntityCount ?? 0,
        items: (searchOrderDebts?.entities ?? []) as OrderDebt[],
      }),
    }
  );
};
