import { ExportToCsvButton } from "./ExportToCsvButton";
import { useOrderDebtsForCsv } from "../hooks/useOrderDebtsForCsv";
import { adaptDebtEntityToCsvRow } from "./adaptDebtEntityToCsvRow";

const getFileName = () => `${new Date().getTime()}_debts_export`;

export const ExportDebtsToCsvButton = ({ total }: { total: number }) => {
  const { refetch: fetchCsvDebts } = useOrderDebtsForCsv(total);

  const getCsvRows = () =>
    fetchCsvDebts().then((res) =>
      (res.data ?? []).map((item) => adaptDebtEntityToCsvRow(item!))
    );

  return (
    <ExportToCsvButton
      maxItemsToExport={1000}
      getFileName={getFileName}
      total={total ?? 0}
      getCsvRows={getCsvRows}
      options={{
        useKeysAsHeaders: true,
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useTextFile: false,
        useBom: true,
      }}
    />
  );
};
