import { notification } from "antd";
import { QueryClient } from "react-query";

const handleError = (err: any) => {
  notification.error({
    message: err?.response?.errors?.[0]?.message || "Something went wrong.",
  });
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: handleError,
    },
    mutations: {
      onError: handleError,
    },
  },
});
