import Cookies from "js-cookie";

import { getSdk } from "@/generated/graphql";
import { AccessDomain } from "@/acl";

import { graphqlClient } from "./graphqlClient";

interface GetApiClientInput {
  domain?: AccessDomain;
}

export const getApiClient = ({
  domain = AccessDomain.OrderManagement,
}: GetApiClientInput = {}) =>
  getSdk(graphqlClient, (action) =>
    action({
      "access-domain": domain,
      Authorization: `Bearer ${Cookies.get("t")}`,
    })
  );
