import { COUNTRIES_VALUES } from "@retail/i18n/constants";
import { DebtType, labelByDebtType } from "../constants";
import localizedCountries from "localized-countries/data/en_GB.json";

export enum PaymentType {
  Cash = "CASH",
  CashPlusFinancing = "CASH_AND_FINANCE",
  Financing = "FINANCE",
}

export const labelByPaymentType: Record<PaymentType, string> = {
  [PaymentType.Cash]: "Cash",
  [PaymentType.CashPlusFinancing]: "Cash + Financing",
  [PaymentType.Financing]: "Financing",
};

type CountryCode = keyof typeof localizedCountries;

export const toLocalizedCountry = (countryCode: CountryCode) =>
  localizedCountries[countryCode];

export const countryOptions = Object.values(COUNTRIES_VALUES).map(
  (country) => ({
    label: toLocalizedCountry(country as CountryCode),
    value: country as CountryCode,
  })
);

export const debtTypeOptions = [
  {
    value: DebtType.TradeIn,
    label: labelByDebtType[DebtType.TradeIn],
  },
  {
    value: DebtType.DoubleRefund,
    label: labelByDebtType[DebtType.DoubleRefund],
  },
  {
    value: DebtType.Chargeback,
    label: labelByDebtType[DebtType.Chargeback],
  },
  {
    value: DebtType.CustomerOwesMoney,
    label: labelByDebtType[DebtType.CustomerOwesMoney],
  },
  {
    value: DebtType.Financing,
    label: labelByDebtType[DebtType.Financing],
  },
  {
    value: DebtType.TradeInPurchasePending,
    label: labelByDebtType[DebtType.TradeInPurchasePending],
  },
];

export const paymentTypeOptions = [
  {
    value: PaymentType.Cash,
    label: labelByPaymentType[PaymentType.Cash],
  },
  {
    value: PaymentType.CashPlusFinancing,
    label: labelByPaymentType[PaymentType.CashPlusFinancing],
  },
  {
    value: PaymentType.Financing,
    label: labelByPaymentType[PaymentType.Financing],
  },
];

enum PaymentFlow {
  PayOnDelivery = "PAY_ON_DELIVERY",
  PayLater = "PAY_LATER",
}

export const paymentFlowOptions = [
  {
    label: "Pay on delivery",
    value: PaymentFlow.PayOnDelivery,
  },
  {
    label: "Pay later",
    value: PaymentFlow.PayLater,
  },
];

export const eligibleForCollectionOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const placeholderByField = {
  dateFrom: "From",
  dateTo: "To",
  debtType: "Debt type",
  country: "Select Country",
  orderNumber: "Order Number",
  paymentType: "Payment type",
  paymentFlow: "Payment flow",
  eligibleForCollection: "Eligible for collection",
};
