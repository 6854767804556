import { useMemo } from "react";
import { getApiClient } from "@/apiClient";
import { countryOptions } from "@/pages/DebtCollectionOverview/DebtCollectionFilter/constants";
import { useQuery } from "react-query";

export const AUX_USER_ACCESS_PARAM_VALUE_ALL = "*";
export const AUX_USER_ACCESS_PARAM_VALUE_NONE = "_";

export const hasAccess = (value: string, userAccess: string[]) => {
  if (userAccess.includes(AUX_USER_ACCESS_PARAM_VALUE_ALL)) {
    return true;
  }

  if (userAccess.includes(AUX_USER_ACCESS_PARAM_VALUE_NONE)) {
    return false;
  }

  return userAccess.includes(value);
};

export const useUserAccessibleCountries = () => {
  const { data } = useQuery(
    ["countries"],
    () => getApiClient().UserPermissions(),
    { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  return useMemo(() => {
    const userAccess = (data?.me?.accessParameters ?? [])
      ?.filter((item) => item?.key === "country" && item.value)
      .map((item) => item?.value) as string[];

    return countryOptions.filter(
      ({ value }) => hasAccess(value, userAccess),
      countryOptions
    );
  }, [data?.me?.accessParameters]);
};
