import { format, parseISO } from "date-fns";
import { CsvRow } from "./ExportToCsvButton";
import { formatCurrency } from "../formatCurrency";
import { OrderDebtForCsv } from "../hooks/useOrderDebtsForCsv/useOrderDebtsForCsv";

enum DebtCsvColumn {
  Empty = " ",
  Empty1 = "  ",
  OrderNumber = "Order Number",
  // for duplicate columns, a hack with additional space is used
  OrderNumber1 = "Order Number ",
  Salutation = "Salutation",
  CustomerName = "Customer Name",
  SecondName = "Name 2",
  CustomerBillingAddress = "Customer Billing adress",
  ZipCode = "Zip code",
  City = "City",
  CustomerPhoneNumber = "Customer Phone Number",
  CustomerEmailAddress = "Customer Email address",
  OrderId = "Order Id",
  DocumentType = "Document type",
  ReasonOfDebt = "Reason of Debt 1",
  DunningStage = "Dunning stage",
  OrderDate = "Order Date",
  OrderDate1 = "Order Date ",
  AmountOutstanding = "Amount Outstanding",
  Currency = "Currency",
}

const formatDate = (isoDateTime: string) => {
  if (!isoDateTime) return "";
  return format(parseISO(isoDateTime), "yyyy-MM-dd");
};

export const adaptDebtEntityToCsvRow = (
  debt: OrderDebtForCsv
): CsvRow<DebtCsvColumn> => ({
  [DebtCsvColumn.OrderNumber]: debt.orderNumber ?? "-",
  [DebtCsvColumn.Salutation]: debt.customerSalutation ?? "-",
  [DebtCsvColumn.CustomerName]: debt.customerName ?? "-",
  [DebtCsvColumn.Empty]: "      ",
  [DebtCsvColumn.SecondName]: debt.customerName2 ?? "-",
  [DebtCsvColumn.CustomerBillingAddress]: debt.customerBillingAddress ?? "-",
  [DebtCsvColumn.ZipCode]: debt.customerBillingAddressZipCode ?? "-",
  [DebtCsvColumn.City]: debt.customerBillingAddressCity ?? "-",
  [DebtCsvColumn.CustomerPhoneNumber]: debt.customerPhoneNumber ?? "-",
  [DebtCsvColumn.CustomerEmailAddress]: debt.customerEmail ?? "-",
  [DebtCsvColumn.Empty1]: "      ",
  [DebtCsvColumn.OrderId]: debt.orderId ?? "-",
  [DebtCsvColumn.OrderNumber1]: debt.orderNumber ?? "-",
  [DebtCsvColumn.DocumentType]: debt.documentType ?? "-",
  [DebtCsvColumn.ReasonOfDebt]: (debt.debtType ?? "-").replace(/\_/gm, "-"),
  [DebtCsvColumn.DunningStage]: debt.dunningState ?? "-",
  [DebtCsvColumn.OrderDate]: debt.orderCreatedOn
    ? formatDate(debt.orderCreatedOn)
    : "-",
  [DebtCsvColumn.AmountOutstanding]: formatCurrency(debt.debtAmount!),
  [DebtCsvColumn.Currency]: debt.debtAmount?.currencyCode ?? "-",
  [DebtCsvColumn.OrderDate1]: debt.orderCreatedOn
    ? formatDate(debt.orderCreatedOn)
    : "-",
});
