import { CommentOutlined } from "@ant-design/icons";
import { Button } from "antd";

export const CommentsIndicator = ({
  total,
  onClick,
}: {
  total: number;
  onClick: () => void;
}) => {
  return (
    <Button
      icon={<CommentOutlined />}
      size="small"
      shape="round"
      onClick={onClick}
    >
      {total ? <span>{total}</span> : null}
    </Button>
  );
};
