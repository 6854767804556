import { useHasPermission } from "@/acl";
import { routePathByPage } from "@/routePathByPage";
import { EditOutlined } from "@ant-design/icons";

import { Button, Table } from "antd";
import { format, parseISO } from "date-fns";
import { orderBy } from "lodash/fp";
import { useCallback, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import {
  labelByOrderPaymentFlow,
  OrderPaymentFlow,
  DATE_FORMAT,
  DebtType,
  labelByDebtType,
} from "../constants";
import { formatCurrency } from "../formatCurrency";
import { useLang, OrderDebt, defaultPagination, useTableState } from "../hooks";
import { CommentsIndicator } from "./CommentsIndicator";
import { CommentsTable } from "./CommentsTable";
import { DebtComment } from "./CommentsTable/commentsTableColumns";
import { MarkDebtAsPaidOffModal } from "./MarkDebtAsPaidOffModal";

import cn from "./DebtCollectionTable.less";

import { usePaymentByOrderId } from "../hooks/usePaymentByOrderId";
import { StockNumberAdminLink } from "@retail/backoffice-ui";

export const formatDate = (isoDateTime: string) => {
  if (!isoDateTime) return "";
  return format(parseISO(isoDateTime), DATE_FORMAT);
};

type DebtCollectionTableProps = {
  debts: OrderDebt[];
  total?: number;
  loading: boolean;
};

export const DebtCollectionTable = ({
  debts,
  total,
  loading,
}: DebtCollectionTableProps) => {
  const [tableState, updateTableState] = useTableState();
  const lang = useLang();

  const hasPermission = useHasPermission();
  const [markDebtAsPaidOffModalState, setMarkDebtAsPaidOffModalState] =
    useState({ visible: false, id: "" });
  const openMarkDebtAsPaidOffModal = (id: string) =>
    setMarkDebtAsPaidOffModalState({ visible: true, id });

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const toggleExpandedRow = useCallback(
    (debtId: string) => {
      if (expandedRowKeys.includes(debtId)) {
        return setExpandedRowKeys(
          expandedRowKeys.filter((key) => key !== debtId)
        );
      }

      return setExpandedRowKeys([...expandedRowKeys, debtId]);
    },
    [expandedRowKeys, setExpandedRowKeys]
  );
  const { data: paymentByOrderId } = usePaymentByOrderId({
    orderIds: debts.map((debt) => debt.orderId),
  });

  const isQaEnv =
    typeof __config !== "undefined" ? __config?.APP_ENV === "qa" : true;
  return (
    <>
      <MarkDebtAsPaidOffModal
        id={markDebtAsPaidOffModalState.id}
        onCancel={() =>
          setMarkDebtAsPaidOffModalState({ visible: false, id: "" })
        }
        visible={markDebtAsPaidOffModalState.visible}
      />
      <Table
        loading={loading}
        className={cn["ant-table"]}
        rowKey="id"
        dataSource={debts}
        onChange={(newPagination, _, sorter) => {
          const sorting = Array.isArray(sorter) ? sorter.at(0) : sorter;

          updateTableState({
            page: newPagination.current ?? defaultPagination.page,
            pageSize: newPagination.pageSize ?? defaultPagination.pageSize,
            sortOrder: sorting?.order,
            sortField: Array.isArray(sorting?.field)
              ? sorting?.field.at(0)
              : sorting?.field,
          });
        }}
        pagination={{
          total: total,
          pageSize: tableState.pageSize,
          current: tableState.page,
        }}
        sticky
        expandable={{
          expandedRowKeys,
          onExpand: (_expanded, record) => toggleExpandedRow(record.id),
          expandedRowRender: (data) => (
            <CommentsTable
              comments={orderBy(
                (val) => parseISO(val.createdOn!),
                "desc",
                (data.comments ?? [])?.filter(Boolean) as DebtComment[]
              )}
              debtId={data.id}
              total={data.comments?.length ?? 0}
            />
          ),
        }}
      >
        <Table.Column<OrderDebt>
          title="Country"
          dataIndex="orderCountry"
          key="orderCountry"
          render={(value) => (
            <span data-qa-selector="table-cell-country">{value}</span>
          )}
        />
        <Table.Column<OrderDebt>
          title="Order Number"
          dataIndex="orderNumber"
          key="orderNumber"
          render={(orderNumber, { orderId }) => (
            <Link
              target="_blank"
              data-qa-selector="table-cell-orderNumber"
              to={generatePath(routePathByPage.orderDetails, { orderId, lang })}
            >
              {orderNumber}
            </Link>
          )}
        />
        <Table.Column<OrderDebt>
          title="Order amount"
          dataIndex="orderAmount"
          key="orderAmount"
          render={(amount) => (
            <span data-qa-selector="table-cell-orderAmount">
              {formatCurrency(amount)}
            </span>
          )}
        />
        <Table.Column<OrderDebt>
          title="Debt amount"
          dataIndex="debtAmount"
          render={(amount) => (
            <span data-qa-selector="table-cell-debtAmount">
              {formatCurrency(amount)}
            </span>
          )}
          key="debtAmount"
          sorter
        />
        <Table.Column<OrderDebt>
          title="Debt type"
          dataIndex="type"
          key="type"
          render={(debtType: DebtType, row) => (
            <span data-qa-selector="table-cell-debtType">
              {labelByDebtType[debtType] || "-"}
              {paymentByOrderId?.[row.orderId]?.externalId &&
              [DebtType.TradeIn, DebtType.TradeInPurchasePending].includes(
                debtType
              ) ? (
                <StockNumberAdminLink
                  stockNumber={
                    paymentByOrderId[row.orderId].externalId as string
                  }
                  isQaEnv={isQaEnv}
                />
              ) : null}
            </span>
          )}
        />
        <Table.Column<OrderDebt>
          title="Debt creation date"
          dataIndex="createdOn"
          key="createdOn"
          render={(date) => (
            <span data-qa-selector="table-cell-debtCreationDate">
              {formatDate(date)}
            </span>
          )}
          sorter
        />
        <Table.Column<OrderDebt>
          title="Order creation date"
          dataIndex="orderCreatedOn"
          key="orderCreatedOn"
          render={(date) => (
            <span data-qa-selector="table-cell-orderCreationDate">
              {formatDate(date)}
            </span>
          )}
          sorter
        />
        <Table.Column<OrderDebt>
          title="Delivery date"
          dataIndex="orderVehicleDeliveredOn"
          key="orderVehicleDeliveredOn"
          sorter
          render={(date) => (
            <span data-qa-selector="table-cell-deliveryDate">
              {formatDate(date)}
            </span>
          )}
        />
        <Table.Column<OrderDebt>
          title="Age of debt"
          dataIndex="eligibleDays"
          key="eligibleDays"
          render={(eligibleDays) => (
            <span data-qa-selector="table-cell-ageOfDebt">
              {eligibleDays || "-"}
            </span>
          )}
        />
        <Table.Column<OrderDebt>
          title="Payment flow"
          dataIndex="orderPaymentFlow"
          key="orderPaymentFlow"
          render={(paymentFlow: OrderPaymentFlow) => (
            <span data-qa-selector="table-cell-paymentFlow">
              {labelByOrderPaymentFlow[paymentFlow] || "-"}
            </span>
          )}
        />
        <Table.Column<OrderDebt>
          title="Eligible for Debt collection"
          dataIndex="eligibleOn"
          key="eligibleOn"
          render={(eligibleOn) => (
            <span data-qa-selector="table-cell-eligibleForDebtCollection">
              {eligibleOn ? "Yes" : "No"}
            </span>
          )}
        />
        <Table.Column<OrderDebt>
          title="Debt-eligibility date"
          dataIndex="eligibleOn"
          key="eligibleOn"
          sorter
          render={(date) => (
            <span data-qa-selector="table-cell-debtEligibilityDate">
              {formatDate(date)}
            </span>
          )}
        />
        <Table.Column<OrderDebt>
          title={<strong data-qa-selector="tableCommentsQuantityTitle" />}
          dataIndex="commentsCount"
          key="commentsCount"
          render={(_, { id, comments }) => (
            <CommentsIndicator
              total={comments?.length ?? 0}
              onClick={() => toggleExpandedRow(id)}
            />
          )}
        />
        {hasPermission("markAsPaidOff") ? (
          <Table.Column<OrderDebt>
            title="Actions"
            dataIndex="id"
            key="id"
            render={(id) => (
              <Button
                type="link"
                shape="round"
                size="small"
                icon={<EditOutlined />}
                onClick={() => openMarkDebtAsPaidOffModal(id)}
                data-qa-selector="Edit"
              />
            )}
          />
        ) : null}
      </Table>
    </>
  );
};
