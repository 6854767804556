import { useState } from "react";
import { Button, Modal } from "antd";
import { Options } from "export-to-csv";

import { saveAsCsv } from "./saveAsCsv";
import { CsvRow } from "./types";

type ExportToCsvButtonProps<T extends string> = {
  total: number;
  getCsvRows: () => Promise<CsvRow<T>[]>;
  getFileName: () => string;
  maxItemsToExport: number;
  options?: Options;
};

export const ExportToCsvButton = <T extends string>({
  getFileName,
  total,
  maxItemsToExport,
  getCsvRows,
  options,
}: ExportToCsvButtonProps<T>) => {
  const [opened, setOpened] = useState(false);
  const [warnOpened, setWarnOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const rows = await getCsvRows();
      await saveAsCsv(rows, { filename: getFileName(), ...options });
      setOpened(false);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => setOpened(false);

  return (
    <>
      <Button
        size="large"
        disabled={loading || !total}
        onClick={() =>
          total <= maxItemsToExport ? setOpened(true) : setWarnOpened(true)
        }
      >
        Export Debts
      </Button>
      <Modal
        title="Warning"
        visible={warnOpened}
        onCancel={() => setWarnOpened(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Close"
      >
        Maximum number of records that can be exported is not more than{" "}
        {maxItemsToExport} rows at a time. Please adjust your filters to export
        the data and try again.
      </Modal>
      <Modal
        title="Confirm"
        visible={opened}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okButtonProps={{ loading }}
      >
        Export {total} debt rows?
      </Modal>
    </>
  );
};
