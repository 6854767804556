import { useCallback, useContext } from "react";

import { PermissionsContext } from "./PermissionsProvider";

const permissionsMap = {
  searchOrderDebts: ["gqls://order.management/query/searchorderdebts"],
  exportDebtsToCsv: ["gqls://order.management/query/orderdebtscsvdata"],
  markAsPaidOff: ["gqls://order.management/mutation/orderdebtsedit"],
  createDebtComment: ["gqls://order.management/mutation/createdebtcomment"],
};

export type Permission = keyof typeof permissionsMap;

export const useHasPermission = () => {
  const allowedPermissions = useContext(PermissionsContext);

  return useCallback(
    (permissions: Permission[] | Permission) => {
      const requestedPermissions = Array.isArray(permissions)
        ? permissions
        : [permissions];

      if (!permissions.length) {
        return true;
      }

      return requestedPermissions.every((permission) =>
        permissionsMap[permission].every(
          (permissionId) => allowedPermissions[permissionId]
        )
      );
    },
    [allowedPermissions]
  );
};
