// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".F4JQPQuAfQW4Fpu5jYtQ table {\n  font-size: 13px;\n  word-break: break-word;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DebtCollectionOverview/DebtCollectionTable/DebtCollectionTable.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,sBAAA;AACF","sourcesContent":[".ant-table table {\n  font-size: 13px;\n  word-break: break-word;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ant-table": "F4JQPQuAfQW4Fpu5jYtQ"
};
export default ___CSS_LOADER_EXPORT___;
