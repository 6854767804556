import {
  createFilter,
  createFilterPayload,
  FilterType,
} from "@retail/gql-utils";

export const adaptToSearchPaymentsVariables = ({
  orderIds,
}: {
  orderIds: string[];
}) => ({
  search: createFilterPayload({
    page: 0, // antd pagination starts with 1, but backend - with 0
    pageSize: 100,
    sorts: [],
    filter: createFilter(null as any, FilterType.AND, [
      createFilter("orderId", FilterType.IN, orderIds),
      createFilter("type", FilterType.EQUAL, "TRADE_IN"),
      createFilter("status", FilterType.EQUAL, "SUCCESS"),
    ]),
  }),
});
