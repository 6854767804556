import { RetailOrderDebtUpdateProjectionInput } from "./../../../../generated/graphql";
import { getApiClient } from "@/apiClient";
import { useMutation, useQueryClient } from "react-query";
import { notification } from "antd";

export const useMarkDebtAsPaidOff = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ["useMarkDebtAsPaidOff"],
    (debt: RetailOrderDebtUpdateProjectionInput) =>
      getApiClient().MarkDebtAsPaidOff({ id, debt }),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(["orderDebts"]);
        notification.success({
          message: "Successfully marked debt as paid off",
        });
      },
    }
  );
};
