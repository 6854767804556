import { SearchOrderDebtsForCsvQuery } from "./../../../../generated/graphql";
import { getApiClient } from "@/apiClient";
import { useQuery } from "react-query";
import { useDebtCollectionFilter } from "../../DebtCollectionFilter";
import { adaptToSearchOrderDebtsVariables } from "../adaptToSearchOrderDebtsVariables";

export type OrderDebtForCsv = NonNullable<
  NonNullable<
    NonNullable<SearchOrderDebtsForCsvQuery["orderDebtsCsvData"]>["entities"]
  >[number]
>;

export const useOrderDebtsForCsv = (total: number) => {
  const [filter] = useDebtCollectionFilter();

  return useQuery(
    ["orderDebtsForCsv", filter],
    () =>
      getApiClient().SearchOrderDebtsForCsv(
        adaptToSearchOrderDebtsVariables(filter, { page: 1, pageSize: total })
      ),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: false,
      select: ({ orderDebtsCsvData }) =>
        (orderDebtsCsvData?.entities ?? []).filter(Boolean),
    }
  );
};
