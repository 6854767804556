import { Space, Row, Col, Typography, Pagination } from "antd";
import { useTableState, useOrderDebts, defaultPagination } from "./hooks";
import {
  DebtCollectionFilter,
  useDebtCollectionFilter,
} from "./DebtCollectionFilter";
import { DebtCollectionTable } from "./DebtCollectionTable";
import { ExportDebtsToCsvButton } from "./ExportDebtsToCsvButton";
import { useHasPermission } from "@/acl";
import { useUpdateEffect } from "react-use";

export const DebtCollectionOverview = () => {
  const { data, isFetching, refetch } = useOrderDebts();
  const [tableState, setTableState] = useTableState();
  const [filter] = useDebtCollectionFilter();

  useUpdateEffect(() => {
    setTableState({ ...tableState, page: defaultPagination.page });
  }, [filter]);

  const hasPermission = useHasPermission();

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ padding: 20, maxWidth: "100%", width: "100%" }}
    >
      <Row align="middle" justify="space-between" wrap>
        <Col sm={18} xs={24}>
          <Typography.Title>Debt Collection - Overview</Typography.Title>
        </Col>
        {hasPermission("exportDebtsToCsv") ? (
          <Col>
            <ExportDebtsToCsvButton total={data?.total} />
          </Col>
        ) : null}
      </Row>
      <DebtCollectionFilter onSearch={refetch} />
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Title level={4} data-qa-selector="total-results-count">
            {data?.total} total results
          </Typography.Title>
        </Col>
        <Col>
          <Pagination
            current={tableState.page}
            total={data?.total}
            pageSize={tableState.pageSize}
            hideOnSinglePage
            onChange={(page, pageSize) =>
              setTableState({ ...tableState, pageSize, page })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1} style={{ maxWidth: "100%", overflow: "auto" }}>
          <DebtCollectionTable
            debts={data?.items ?? []}
            total={data?.total}
            loading={isFetching}
          />
        </Col>
      </Row>
    </Space>
  );
};
