// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".apP8nQ5hDU06zrXsKbpt {\n  grid-area: cancel;\n}\n.D8BTqpvvQTScyqqpHQKg {\n  grid-area: save;\n  margin-left: 15px;\n}\n.BEuDKWe3Gv8rwI4qBNN9 {\n  display: grid;\n  grid-template-columns: 1fr auto auto;\n  grid-template-areas: \"form form form\" \". cancel save\";\n  padding: 5px;\n  margin-bottom: 10px;\n  border: 1px solid #f0f0f0;\n}\n.BEuDKWe3Gv8rwI4qBNN9 > div {\n  grid-area: form;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DebtCollectionOverview/DebtCollectionTable/CommentsTable/AddCommentForm/styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAEA;EACE,eAAA;EACA,iBAAA;AAAF;AAGA;EACE,aAAA;EACA,oCAAA;EACA,qDAAA;EAGA,YAAA;EACA,mBAAA;EACA,yBAAA;AAHF;AAKE;EACE,eAAA;AAHJ","sourcesContent":[".cancel {\n  grid-area: cancel;\n}\n\n.save {\n  grid-area: save;\n  margin-left: 15px;\n}\n\n.form {\n  display: grid;\n  grid-template-columns: 1fr auto auto;\n  grid-template-areas:\n    \"form form form\"\n    \". cancel save\";\n  padding: 5px;\n  margin-bottom: 10px;\n  border: 1px solid #f0f0f0;\n\n  & > div {\n    grid-area: form;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancel": "apP8nQ5hDU06zrXsKbpt",
	"save": "D8BTqpvvQTScyqqpHQKg",
	"form": "BEuDKWe3Gv8rwI4qBNN9"
};
export default ___CSS_LOADER_EXPORT___;
