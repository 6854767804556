import { Form, FormItemProps } from "antd";
import { ReactNode } from "react";
import {
  Control,
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
} from "react-hook-form";

type FieldProps<T extends FieldValues, U extends FieldPath<T>> = {
  name: U;
  label?: ReactNode;
  control: Control<T>;
  render: ControllerProps<T, U>["render"];
} & FormItemProps;

export const FormField = <T extends FieldValues, U extends FieldPath<T>>({
  name,
  label,
  control,
  render,
  ...formItemProps
}: FieldProps<T, U>) => (
  <Controller<T, U>
    name={name}
    control={control}
    render={(props) => (
      <Form.Item
        label={label}
        validateStatus={
          props.fieldState.error && props.fieldState.error?.message
            ? "error"
            : undefined
        }
        help={props.fieldState.error?.message}
        {...formItemProps}
      >
        {render(props)}
      </Form.Item>
    )}
  />
);
