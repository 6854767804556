import { Button, Input } from "antd";
import { useForm } from "react-hook-form";

import cn from "./styles.less";

import { useMutation } from "react-query";
import { FormField } from "@/pages/DebtCollectionOverview/FormField";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const { TextArea } = Input;

type AddCommentFormProps = {
  createComment: (values: { comment: string }) => Promise<unknown>;
  onCancel: () => void;
};

const addCommentValidationSchema = z.object({
  comment: z
    .string({
      invalid_type_error: "Required",
      required_error: "Required",
    })
    .trim()
    .min(1, "Comment must contain at least 1 character"),
});

type AddCommentVM = z.infer<typeof addCommentValidationSchema>;

export const AddCommentForm = ({
  createComment,
  onCancel,
}: AddCommentFormProps) => {
  const { handleSubmit, control, reset } = useForm<AddCommentVM>({
    mode: "onSubmit",
    resolver: zodResolver(addCommentValidationSchema),
  });
  const createCommentMutation = useMutation(createComment);

  const onSubmit = async ({ comment }: AddCommentVM) => {
    await createCommentMutation.mutateAsync({
      comment,
    });
    await reset();
  };

  const onCancelClick = () => {
    reset();
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn.form}>
      <FormField
        control={control}
        name="comment"
        render={({ field, fieldState }) => (
          <TextArea
            disabled={createCommentMutation.isLoading}
            autoFocus
            rows={3}
            maxLength={300}
            status={fieldState.error ? "error" : undefined}
            placeholder="Type here..."
            required
            className={cn.textarea}
            {...field}
          />
        )}
      />

      <Button
        data-qa-selector="cancel"
        disabled={createCommentMutation.isLoading}
        htmlType="button"
        type="default"
        onClick={onCancelClick}
        className={cn.cancel}
      >
        Cancel
      </Button>
      <Button
        data-qa-selector="addComment"
        disabled={createCommentMutation.isLoading}
        htmlType="submit"
        type="primary"
        className={cn.save}
        loading={createCommentMutation.isLoading}
      >
        Save
      </Button>
    </form>
  );
};
