import { useMemo, useCallback } from "react";
import { format, isValid, parse } from "date-fns";
import { DATE_FORMAT, DebtType, OrderPaymentFlow } from "../constants";
import { PaymentType } from "./constants";
import { useSearchParams } from "react-router-dom";

const formatDate = (date: Date | undefined) =>
  date && isValid(date) ? format(date, DATE_FORMAT) : undefined;

export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const formatDateTime = (date: Date | undefined) =>
  date && isValid(date) ? format(date, DATE_TIME_FORMAT) : undefined;

const parseDate = (date: string | undefined) =>
  date ? parse(date, DATE_FORMAT, new Date()) : null;

type DateRange = readonly [Date | null, Date | null];
export type DebtCollectionFilterVM = {
  country?: string[];
  orderCreationDateRange?: DateRange;
  debtCreationDateRange?: DateRange;
  eligibleOnDateRange?: DateRange;
  deliveryDateRange?: DateRange;
  orderNumber?: string;
  debtType?: DebtType;
  paymentType?: PaymentType;
  eligibleForCollection?: boolean;
  paymentFlow?: string;
};

export const useDebtCollectionFilter = () => {
  const [search, setSearch] = useSearchParams();

  const countries = search.getAll("country");
  const orderNumber = search.get("orderNumber") ?? undefined;
  const debtType = search.get("debtType") as DebtType;
  const paymentType = search.get("paymentType") as PaymentType;
  const paymentFlow = search.get("paymentFlow") as OrderPaymentFlow;
  const eligibleForCollection = search.get("eligibleForCollection");
  const eligibleOnDateRangeFrom = search.get("eligibleOnDateRangeFrom");
  const eligibleOnDateRangeTo = search.get("eligibleOnDateRangeTo");
  const orderCreationDateRangeFrom = search.get("orderCreationDateRangeFrom");
  const orderCreationDateRangeTo = search.get("orderCreationDateRangeTo");
  const deliveryDateRangeFrom = search.get("deliveryDateRangeFrom");
  const deliveryDateRangeTo = search.get("deliveryDateRangeTo");
  const debtCreationDateRangeFrom = search.get("debtCreationDateRangeFrom");
  const debtCreationDateRangeTo = search.get("debtCreationDateRangeTo");

  const filter: DebtCollectionFilterVM = useMemo(
    () => ({
      country: countries,
      orderNumber,
      debtType,
      paymentType,
      paymentFlow,
      eligibleForCollection:
        typeof eligibleForCollection === "string"
          ? eligibleForCollection === "true"
            ? true
            : false
          : undefined,
      eligibleOnDateRange: [
        parseDate(eligibleOnDateRangeFrom ?? undefined),
        parseDate(eligibleOnDateRangeTo ?? undefined),
      ],
      orderCreationDateRange: [
        parseDate(orderCreationDateRangeFrom ?? undefined),
        parseDate(orderCreationDateRangeTo ?? undefined),
      ],
      debtCreationDateRange: [
        parseDate(debtCreationDateRangeFrom ?? undefined),
        parseDate(debtCreationDateRangeTo ?? undefined),
      ],
      deliveryDateRange: [
        parseDate(deliveryDateRangeFrom ?? undefined),
        parseDate(deliveryDateRangeTo ?? undefined),
      ],
    }),
    [
      countries.toString(),
      orderNumber,
      debtType,
      debtCreationDateRangeFrom,
      debtCreationDateRangeTo,
      paymentType,
      paymentFlow,
      eligibleOnDateRangeFrom,
      eligibleOnDateRangeTo,
      eligibleForCollection,
      orderCreationDateRangeFrom,
      orderCreationDateRangeTo,
      deliveryDateRangeFrom,
      deliveryDateRangeTo,
    ]
  );

  const setFilter = useCallback(
    ({
      country,
      orderNumber,
      debtType,
      paymentType,
      paymentFlow,
      eligibleForCollection,
      eligibleOnDateRange,
      deliveryDateRange,
      orderCreationDateRange,
      debtCreationDateRange,
    }: DebtCollectionFilterVM) => {
      const updateValue = (key: string, value?: string) => {
        if (!value) {
          search.delete(key);
        } else {
          search.set(key, value);
        }
      };
      search.delete("country");
      country?.map((c) => search.append("country", c));

      updateValue("orderNumber", orderNumber);
      updateValue("debtType", debtType);
      updateValue("paymentType", paymentType);
      updateValue("paymentFlow", paymentFlow);
      updateValue(
        "eligibleForCollection",
        typeof eligibleForCollection === "boolean"
          ? eligibleForCollection
            ? "true"
            : "false"
          : undefined
      );
      updateValue(
        "eligibleOnDateRangeFrom",
        formatDate(eligibleOnDateRange?.[0] ?? undefined)
      );
      updateValue(
        "eligibleOnDateRangeTo",
        formatDate(eligibleOnDateRange?.[1] ?? undefined)
      );
      updateValue(
        "orderCreationDateRangeFrom",
        formatDate(orderCreationDateRange?.[0] ?? undefined)
      );
      updateValue(
        "orderCreationDateRangeTo",
        formatDate(orderCreationDateRange?.[1] ?? undefined)
      );
      updateValue(
        "deliveryDateRangeFrom",
        formatDate(deliveryDateRange?.[0] ?? undefined)
      );
      updateValue(
        "deliveryDateRangeTo",
        formatDate(deliveryDateRange?.[1] ?? undefined)
      );
      updateValue(
        "debtCreationDateRangeFrom",
        formatDate(debtCreationDateRange?.[0] ?? undefined)
      );
      updateValue(
        "debtCreationDateRangeTo",
        formatDate(debtCreationDateRange?.[1] ?? undefined)
      );

      setSearch(search, { replace: true });
    },
    [setSearch, search]
  );

  return [filter, setFilter] as const;
};
