import { get, set } from "lodash/fp";
import * as Intl from "@retail/i18n/Intl"; // TODO: https://wkdauto.atlassian.net/browse/RET-817
import {
  unitsFromMinorToMajor,
  currencyInfoByCode,
  getCurrencyCodeFromPrice,
} from "@retail/currency";

const LOCALE = "en-GB";

const formatters = {
  currency: {},
};

const getCurrencyFormat = (currencyCode: string, locale = LOCALE) => {
  let currencyFormat = get(["locale", "currencyCode"], formatters);

  if (!currencyFormat) {
    try {
      currencyFormat = new Intl.NumberFormat(locale, {
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: "currency",
        currencyDisplay: "symbol",
      });
      set([locale, currencyCode], currencyFormat, formatters);
    } catch (e) {
      return null;
    }
  }

  return currencyFormat;
};

export const formatCurrency = ({
  amountMinorUnits,
  currency,
  currencyCode,
  conversionMajor,
}: {
  amountMinorUnits: any;
  currency?: any;
  currencyCode?: any;
  conversionMajor?: any;
}) => {
  // NOTE old APIs use 'currency' instead of 'currencyCode'
  const code = getCurrencyCodeFromPrice({ currency, currencyCode });

  const currencyInfo = code ? get([code], currencyInfoByCode) : null;

  const currencyFormat = code
    ? getCurrencyFormat(code, get("formatLocale", currencyInfo))
    : "";

  if (!conversionMajor) {
    conversionMajor = get("conversionMajor", currencyInfo);
  }

  const amount = unitsFromMinorToMajor(amountMinorUnits, conversionMajor);

  if (!currencyFormat) {
    return amount;
  }

  return currencyFormat.format(amount);
};
