import { ConfigProvider } from "antd";
import { QueryClientProvider } from "react-query";
import { ANTD_EXTERNAL_VERSION_LESS } from "@/antdConstants";
import { queryClient } from "@/apiClient";
import { PermissionsProvider } from "./acl";
import { Routing } from "./Routing";

export default function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <PermissionsProvider>
        <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
          <Routing />
        </ConfigProvider>
      </PermissionsProvider>
    </QueryClientProvider>
  );
}
