import { Button, Table } from "antd";
import { useState } from "react";

import cn from "./styles.less";
import { AddCommentForm } from "./AddCommentForm";
import { commentsTableColumns, DebtComment } from "./commentsTableColumns";
import { useCreateDebtComment } from "./hooks";
import { useHasPermission } from "@/acl";

type CommentsTableProps = {
  total: number;
  debtId: string;
  comments: DebtComment[];
};

export const CommentsTable = ({
  debtId,
  comments,
  total,
}: CommentsTableProps) => {
  const hasNoComments = total === 0;
  const [isCommentFormOpen, setCommentFormOpen] = useState(hasNoComments);
  const openCommentForm = () => setCommentFormOpen(true);
  const closeCommentForm = () => setCommentFormOpen(false);
  const createDebtCommentMutation = useCreateDebtComment();

  const hasPermission = useHasPermission();
  const show = hasPermission("createDebtComment") && isCommentFormOpen;

  return (
    <div className={cn.comments}>
      <div data-qa-selector={`header-comments-${debtId}`} className={cn.header}>
        <p data-qa-selector="commentsQuantity" className={cn.commentsCount}>
          Comments ({total})
        </p>
        {!show && (
          <Button
            type="primary"
            data-qa-selector="createComment"
            onClick={openCommentForm}
          >
            Add Comment
          </Button>
        )}
      </div>

      {show && (
        <AddCommentForm
          createComment={async (values) =>
            createDebtCommentMutation.mutateAsync({
              debtId,
              text: values.comment,
            })
          }
          onCancel={closeCommentForm}
        />
      )}

      <Table
        bordered
        rowKey="id"
        columns={commentsTableColumns}
        pagination={false}
        className={cn.table}
        rowClassName="debt-comments-row"
        data-qa-selector="debt-comments-table"
        dataSource={comments}
      />
    </div>
  );
};
