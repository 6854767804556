import { Button, Form, Modal } from "antd";
import { useForm } from "react-hook-form";
import { useUpdateEffect } from "react-use";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { DATE_FORMAT } from "../constants";
import { DatePicker } from "../DebtCollectionFilter/DateRangePicker";
import Label from "../DebtCollectionFilter/Label";
import { useMarkDebtAsPaidOff } from "../hooks/useMarkDebtAsPaidOff";
import { FormField } from "../FormField";
import { isFuture } from "date-fns";
import { formatISODateTime } from "../hooks/useOrderDebts/utils";

const markDebtAsPaidOffValidationSchema = z.object({
  payOffDate: z.date({
    invalid_type_error: "Required",
    required_error: "Required",
  }),
});

export const MarkDebtAsPaidOffModal = ({
  id,
  visible,
  onCancel,
}: {
  visible: boolean;
  onCancel: () => void;
  id: string;
}) => {
  const methods = useForm<{ payOffDate: Date }>({
    reValidateMode: "onBlur",
    resolver: zodResolver(markDebtAsPaidOffValidationSchema),
  });

  const markDebtAsPaidOff = useMarkDebtAsPaidOff(id);

  useUpdateEffect(() => {
    if (!visible) {
      methods.reset();
    }
  }, [visible]);

  const handleSubmit = methods.handleSubmit(async (values) => {
    await markDebtAsPaidOff.mutateAsync({
      paidOffOn: formatISODateTime(values.payOffDate),
    });
    onCancel();
  });

  return (
    <Modal
      title="Mark as paid off"
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel"
          data-qa-selector="mark-as-paid-off_cancel"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          key="confirm"
          data-qa-selector="mark-as-paid-off_confirm"
          loading={markDebtAsPaidOff.isLoading}
          disabled={!!methods.formState.errors.payOffDate}
          type="primary"
          onClick={handleSubmit}
        >
          Confirm
        </Button>,
      ]}
    >
      <Form name="debt-collection-overview-search" layout="vertical">
        <FormField
          label={<Label text="Pay-Off Date" />}
          control={methods.control}
          name="payOffDate"
          render={({ field, fieldState }) => (
            <DatePicker.DatePicker
              {...field}
              size="large"
              data-qa-selector="mark-as-paid-off_payOffDate"
              status={fieldState.error ? "error" : undefined}
              style={{ minWidth: 200 }}
              disabledDate={isFuture}
              placeholder="Select the date"
              format={DATE_FORMAT}
              allowClear
            />
          )}
        />
      </Form>
    </Modal>
  );
};
