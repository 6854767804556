import { ColumnsType } from "antd/lib/table";
import { formatDateTime } from "../../DebtCollectionFilter";
import { OrderDebt } from "../../hooks";

export type DebtComment = NonNullable<
  NonNullable<OrderDebt["comments"]>[number]
>;

export const commentsTableColumns: ColumnsType<DebtComment> = [
  {
    title: <strong data-qa-selector="tableTitleCommentCreatedOn">Date</strong>,
    dataIndex: "createdOn",
    key: "createdOn",
    width: 200,
    render: (createdOn) => (
      <span data-qa-selector="createdOn">
        {formatDateTime(new Date(createdOn))}
      </span>
    ),
  },
  {
    title: <strong data-qa-selector="tableTitleCommentCreatedBy">From</strong>,
    dataIndex: "createdBy",
    key: "createdBy",
    width: 300,
    render: (_, { createdBy }) => {
      const { firstName, lastName } = createdBy || {};

      if (firstName || lastName) {
        return `${firstName || ""} ${lastName || ""}`;
      }

      return "_";
    },
  },
  {
    title: <strong data-qa-selector="tableTitleCommentContent">Comment</strong>,
    dataIndex: "text",
    key: "text",
    render: (comment) => <span data-qa-selector="comment">{comment}</span>,
  },
];
