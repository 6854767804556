import { memo, useEffect, useRef } from "react";
import { Card, Input, Space, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { RangePicker } from "./DateRangePicker";
import Label from "./Label";
import {
  debtTypeOptions,
  eligibleForCollectionOptions,
  paymentFlowOptions,
  paymentTypeOptions,
  placeholderByField,
} from "./constants";
import { SelectField } from "./SelectField";
import { useUserAccessibleCountries } from "./useUserAccessibleCountries";
import { useDebtCollectionFilter } from "./useDebtCollectionFilter";

import cn from "./styles.less";
import { DATE_FORMAT } from "../constants";

const { useForm } = Form;

type DebtCollectionFilterProps = {
  onSearch: () => void;
};

export const DebtCollectionFilter = memo(
  ({ onSearch }: DebtCollectionFilterProps) => {
    const [initialValues, handleSubmit] = useDebtCollectionFilter();
    const [form] = useForm();

    useEffect(() => {
      if (initialValues) {
        form.setFieldsValue(initialValues);
      }
    }, [initialValues, form]);

    const countryOptions = useUserAccessibleCountries();

    const ref = useRef(false);
    useEffect(() => {
      if (!ref.current) {
        if (countryOptions.length === 1) {
          ref.current = true;
          form.setFields([
            { name: "country", value: countryOptions.map((v) => v.value) },
          ]);

          handleSubmit({
            ...initialValues,
            country: countryOptions.map((v) => v.value),
          });
        }
      }
    }, [countryOptions, form, handleSubmit, initialValues]);

    return (
      <Card className={cn.container}>
        <Form
          name="debt-collection-overview-search"
          form={form}
          initialValues={initialValues}
          onFinish={handleSubmit}
        >
          <Space align="end" wrap={true}>
            <Label text="Country">
              <SelectField
                mode="multiple"
                name="country"
                options={countryOptions}
                placeholder={placeholderByField.country}
                data-qa-selector="countrySelect"
              />
            </Label>
            <Label text="Debt-eligibility date">
              <Form.Item name="eligibleOnDateRange" noStyle={true}>
                <RangePicker
                  size="large"
                  placeholder={[
                    placeholderByField.dateFrom,
                    placeholderByField.dateTo,
                  ]}
                  format={DATE_FORMAT}
                  allowClear
                  allowEmpty={[true, true]}
                  className={cn.minWidth}
                  data-qa-selector="eligibleOnDateRange"
                />
              </Form.Item>
            </Label>
            <Label text="Debt creation date">
              <Form.Item name="debtCreationDateRange" noStyle={true}>
                <RangePicker
                  size="large"
                  placeholder={[
                    placeholderByField.dateFrom,
                    placeholderByField.dateTo,
                  ]}
                  format={DATE_FORMAT}
                  allowClear
                  allowEmpty={[true, true]}
                  className={cn.minWidth}
                  data-qa-selector="debtCreationDateRange"
                />
              </Form.Item>
            </Label>
            <Label text="Order creation date">
              <Form.Item name="orderCreationDateRange" noStyle={true}>
                <RangePicker
                  size="large"
                  placeholder={[
                    placeholderByField.dateFrom,
                    placeholderByField.dateTo,
                  ]}
                  format={DATE_FORMAT}
                  allowClear
                  allowEmpty={[true, true]}
                  className={cn.minWidth}
                  data-qa-selector="orderCreationDateRange"
                />
              </Form.Item>
            </Label>
            <Label text="Delivery date">
              <Form.Item name="deliveryDateRange" noStyle={true}>
                <RangePicker
                  size="large"
                  placeholder={[
                    placeholderByField.dateFrom,
                    placeholderByField.dateTo,
                  ]}
                  format={DATE_FORMAT}
                  allowClear
                  allowEmpty={[true, true]}
                  className={cn.minWidth}
                  data-qa-selector="deliveryDateRange"
                />
              </Form.Item>
            </Label>
            <Label text="Order Number">
              <Form.Item name="orderNumber" noStyle={true}>
                <Input
                  size="large"
                  placeholder={placeholderByField.orderNumber}
                  className={cn.minWidth}
                  name="orderNumber"
                  data-qa-selector="orderNumberInput"
                />
              </Form.Item>
            </Label>
            <Label text="Debt type">
              <SelectField
                name="debtType"
                options={debtTypeOptions}
                placeholder={placeholderByField.debtType}
                data-qa-selector="debtTypeSelect"
              />
            </Label>
            <Label text="Payment type">
              <SelectField
                name="paymentType"
                options={paymentTypeOptions}
                placeholder={placeholderByField.paymentType}
                data-qa-selector="paymentTypeSelect"
              />
            </Label>
            <Label text="Payment flow">
              <SelectField
                name="paymentFlow"
                options={paymentFlowOptions}
                placeholder={placeholderByField.paymentFlow}
                data-qa-selector="paymentFlowSelect"
              />
            </Label>
            <Label text="Eligible for collection">
              <SelectField
                name="eligibleForCollection"
                options={eligibleForCollectionOptions}
                placeholder={placeholderByField.eligibleForCollection}
                data-qa-selector="eligibleForCollectionSelect"
              />
            </Label>
            <Button
              onClick={onSearch}
              icon={<SearchOutlined />}
              size="large"
              type="primary"
              htmlType="submit"
            />
          </Space>
        </Form>
      </Card>
    );
  }
);
