import { useQuery } from "react-query";
import { getApiClient } from "@/apiClient";
import { SearchRetailPaymentsQuery } from "@/generated/graphql";
import { useOrderDebts } from "../useOrderDebts";
import { adaptToSearchPaymentsVariables } from "./adaptToSearchPaymentsVariables";
import { DebtType } from "../../constants";

export type Payment = NonNullable<
  NonNullable<
    NonNullable<SearchRetailPaymentsQuery["searchRetailPayments"]>["entities"]
  >[number]
>;

export const usePaymentByOrderId = ({
  orderIds = [],
}: {
  orderIds?: string[];
}) => {
  return useQuery(
    ["payments", orderIds],
    () =>
      getApiClient().SearchRetailPayments(
        adaptToSearchPaymentsVariables({ orderIds })
      ),
    {
      enabled: orderIds.length > 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: ({ searchRetailPayments }) =>
        (searchRetailPayments?.entities ?? []).reduce((prev, curr) => {
          if (curr?.orderId && curr?.externalId) {
            prev[curr?.orderId] = curr as Payment;
          }
          return prev;
        }, {} as Record<string, Payment>),
    }
  );
};
