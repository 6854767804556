import { Form, Select, SelectProps } from "antd";

import cn from "./styles.less";

const { Option } = Select;

type SelectOption<T> = {
  label: string;
  value: T;
};

type SelectFieldProps<T> = {
  placeholder: string;
  options: SelectOption<T>[];
  name: string;
  mode?: SelectProps["mode"];
  ["data-qa-selector"]?: string;
};

export const SelectField = <T extends unknown>({
  placeholder,
  options,
  name,
  mode,
  ...rest
}: SelectFieldProps<T>) => {
  return (
    <Form.Item name={name} noStyle={true}>
      <Select
        className={cn.minWidth}
        size="large"
        mode={mode}
        showSearch={true}
        placeholder={placeholder}
        allowClear={true}
        filterOption={(input, option) => {
          const string =
            (typeof option?.children === "string" ? option.children : "") +
              option?.value ?? "";

          return string.toString().toLowerCase()?.includes(input.toLowerCase());
        }}
        {...rest}
      >
        {options.map(({ value, label }) => (
          <Option
            value={value}
            key={label}
            data-qa-selector-option-value={label}
          >
            {label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
