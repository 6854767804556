// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jHUpRyDGAJNZWoNTvIEl {\n  margin: 10px 0;\n}\n.kVZWHaeb_shTMPqvxStl {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  padding-left: 10px;\n}\n.Yp2iNB30ZCh9LkLFCxhq {\n  margin: 0;\n}\n.GeIKTUPj2a_OR2aUYd8A {\n  width: 100%;\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);\n}\n.GeIKTUPj2a_OR2aUYd8A .ant-table-cell {\n  vertical-align: top;\n}\n.GeIKTUPj2a_OR2aUYd8A tr:nth-child(2n) td {\n  background-color: #f9f9f9;\n}\n.GeIKTUPj2a_OR2aUYd8A .ant-table-thead > tr > th,\n.GeIKTUPj2a_OR2aUYd8A .ant-table-tbody > tr > td {\n  padding: 10px;\n}\n.GeIKTUPj2a_OR2aUYd8A .ant-table-tbody > tr {\n  position: relative;\n}\n.GeIKTUPj2a_OR2aUYd8A .ant-table-tbody .ant-empty {\n  margin: 10px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DebtCollectionOverview/DebtCollectionTable/CommentsTable/styles.less"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AAAF;AAGA;EACE,SAAA;AADF;AAIA;EACE,WAAA;EACA,0CAAA;AAFF;AAAA;EAMM,mBAAA;AAHN;AAHA;EAUM,yBAAA;AAJN;AANA;;EAeM,aAAA;AALN;AAVA;EAmBM,kBAAA;AANN;AAbA;EAuBM,cAAA;AAPN","sourcesContent":[".comments {\n  margin: 10px 0;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  padding-left: 10px;\n}\n\n.commentsCount {\n  margin: 0;\n}\n\n.table {\n  width: 100%;\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);\n\n  :global {\n    .ant-table-cell {\n      vertical-align: top;\n    }\n\n    tr:nth-child(2n) td {\n      background-color: #f9f9f9;\n    }\n\n    .ant-table-thead > tr > th,\n    .ant-table-tbody > tr > td {\n      padding: 10px;\n    }\n\n    .ant-table-tbody > tr {\n      position: relative;\n    }\n\n    .ant-table-tbody .ant-empty {\n      margin: 10px 0;\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comments": "jHUpRyDGAJNZWoNTvIEl",
	"header": "kVZWHaeb_shTMPqvxStl",
	"commentsCount": "Yp2iNB30ZCh9LkLFCxhq",
	"table": "GeIKTUPj2a_OR2aUYd8A"
};
export default ___CSS_LOADER_EXPORT___;
