import { BrowserRouter, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router";
import { FC } from "react";
import { Button, Result } from "antd";
import { useHasPermission } from "./acl";
import routes from "./routes";

const AccessDenied: FC = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page"
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          Back Home
        </Button>
      }
    />
  );
};

export const Routing: FC = () => {
  const hasPermission = useHasPermission();

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ permissions, ...routeProps }) => (
          <Route
            key={routeProps.path}
            {...(hasPermission(permissions)
              ? routeProps
              : { element: <AccessDenied /> })}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};
