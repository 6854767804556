import React, { useMemo } from "react";
import { useQuery } from "react-query";

import { getApiClient } from "@/apiClient";

type ContextType = Record<string, true>;

export const PermissionsContext = React.createContext({} as ContextType);

export const PermissionsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { data, isFetching } = useQuery(
    ["permissions"],
    () => getApiClient().UserPermissions(),
    { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  const permissionsMap = useMemo(() => {
    const result = {} as ContextType;

    data?.me?.effectivePermissions?.forEach((permission) => {
      if (permission) {
        result[permission] = true;
      }
    });

    return result;
  }, [data]);

  return (
    <PermissionsContext.Provider value={permissionsMap}>
      {isFetching ? null : children}
    </PermissionsContext.Provider>
  );
};
