import { getApiClient } from "@/apiClient";
import { CreateDebtCommentMutationVariables } from "@/generated/graphql";
import { notification } from "antd";
import { useMutation, useQueryClient } from "react-query";

export const useCreateDebtComment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ["useCreateDebtComment"],
    (variables: CreateDebtCommentMutationVariables) =>
      getApiClient().CreateDebtComment(variables),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(["orderDebts"]);
        notification.success({
          message: "Successfully added comment",
        });
      },
    }
  );
};
