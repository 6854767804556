// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".L_qZZrytUZn1fXS350Gp {\n  width: 100%;\n}\n.TCfKHv89ezFQIZeCf2S3 {\n  min-width: 200px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DebtCollectionOverview/DebtCollectionFilter/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAEA;EACE,gBAAA;AAAF","sourcesContent":[".container {\n  width: 100%;\n}\n\n.minWidth {\n  min-width: 200px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "L_qZZrytUZn1fXS350Gp",
	"minWidth": "TCfKHv89ezFQIZeCf2S3"
};
export default ___CSS_LOADER_EXPORT___;
