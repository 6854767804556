import { PropsWithChildren } from "react";
import { Space, Typography } from "antd";

interface Props {
  text: string;
}

function Label({ text, children }: PropsWithChildren<Props>) {
  return (
    <Space direction="vertical" size={0}>
      <Typography.Text>{text}</Typography.Text>
      {children}
    </Space>
  );
}

export default Label;
