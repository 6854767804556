import { createFilterPayload, Sorts } from "@retail/gql-utils";
import { DebtCollectionFilterVM } from "../DebtCollectionFilter";
import { TableState } from "./useTableState";
import { buildDebtCollectionFilter } from "./buildDebtCollectionFilter";

const directionEntityByVM = {
  descend: "desk",
  ascend: "ask",
} as const;

const sortFieldByFilterKey: Record<string, string> = {
  createdOn: "createdOn",
  orderCreatedOn: "orderCreatedOn",
  debtAmount: "amountMinorUnits",
  orderVehicleDeliveredOn: "orderVehicleDeliveredOn",
  eligibleDays: "eligibleDays",
  eligibleOn: "eligibleOn",
};
export const adaptToSearchOrderDebtsVariables = (
  filter: DebtCollectionFilterVM,
  tableState: TableState<unknown>
) => ({
  search: createFilterPayload({
    page: tableState.page - 1, // antd pagination starts with 1, but backend - with 0
    pageSize: tableState.pageSize,
    sorts: [
      tableState.sortField && tableState.sortOrder
        ? Sorts[directionEntityByVM[tableState.sortOrder]](
            sortFieldByFilterKey[tableState.sortField]
          )
        : Sorts.desk("createdOn"),
    ],
    filter: buildDebtCollectionFilter(filter),
  }),
});
